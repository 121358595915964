import React from 'react'

// class MyContact extends React.Component {
    
    // render() {
        const Contact = (props) => (
            <section id="contact">
                <div className="inner">
                    {/* <section>
                        <form method="post" action="#">
                            <div className="field half first">
                                <label htmlFor="name">Nome</label>
                                <input type="text" name="name" id="name" />
                            </div>
                            <div className="field half">
                                <label htmlFor="email">Email</label>
                                <input type="text" name="email" id="email" />
                            </div>
                            <div className="field">
                                <label htmlFor="message">Mensagem</label>
                                <textarea name="message" id="message" rows="6"></textarea>
                            </div>
                            <ul className="actions">
                                <li><input type="submit" value="Enviar" className="special" /></li>
                                <li><input type="reset" value="Limpar" /></li>
                            </ul>
                        </form>
                    </section> */}
                    {/* <section className="split"> */}
                        <section>
                            <div className="contact-method">
                                <span className="icon alt fa-envelope"></span>
                                <h3>Email</h3>
                                <a href="mailto:contato@bworks.com.br?subject=Contato">contato@bworks.com.br</a>
                            </div>
                        </section>
                        <section>
                            <div className="contact-method">
                                <span className="icon alt fa-phone"></span>
                                <h3>Tel</h3>
                                <a href="tel:+5511980868861">(11) 980.868.861</a>
                            </div>
                        </section>
                    {/* </section> */}
                </div>
            </section>
        )
        // return Contact
    // }
// }

export default Contact
// export default MyContact
